import React, { useEffect } from "react"
import { useTranslation } from 'react-i18next';
import { navigate } from '@gatsbyjs/reach-router';

const NotFound = () => {
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;

  useEffect(() => {
    setTimeout(() => {
      navigate(`/${lang}/`, { replace: true });
    }, 1000);
  }, []);

  return (
    <h1>{t('404.title')}</h1>
  );
};

export default NotFound;
